import { FC } from "react";
import { Badge } from "react-bootstrap";

interface EnvironmentBadgeProps {
  environment: string;
}

const EnvironmentBadge: FC<EnvironmentBadgeProps> = ({ environment }) => {
  return (
    <Badge
      className="me-1 align-items-center"
      bg={environment == "pro" ? "danger" : "primary"}
    >
      {environment}
    </Badge>
  );
};

export default EnvironmentBadge;
