import { FC } from "react";
import Flag from "react-world-flags";
import Cluster from "../../api/types/clusters/Cluster";
import AWS_REGIONS from "../../utils/AWSRegions";
import ClustersBy from "../../utils/ClustersBy";
import OrFilter from "./OrFilter";

const clusterRegions = (clusters: Cluster[]) => {
  return Array.from(new Set(clusters.map((c) => c.details.region))).sort();
};

type RegionFilterProps = {
  clusters: Cluster[];
  allClusters: Cluster[];
  toggleFilter: {
    value: boolean;
    handler: (action: React.SetStateAction<boolean>) => void;
  };
  state: {
    value: string[];
    handler: (action: React.SetStateAction<string[]>) => void;
  };
};

const RegionFilter: FC<RegionFilterProps> = ({
  clusters,
  allClusters,
  toggleFilter,
  state,
}) => (
  <OrFilter
    filterLabel="Region"
    filterActive={toggleFilter.value}
    setFilterActive={toggleFilter.handler}
    filter={state.value}
    setFilter={state.handler}
    items={allClusters}
    itemsBy={clusterRegions}
    itemsGroupedBy={ClustersBy.byRegion}
    itemLabel={(region) => (
      <span>
        <Flag
          code={AWS_REGIONS.get(region)}
          height={16}
          width={30}
          className="me-2"
        />
        {region} (
        {ClustersBy.byRegion(
          Array.from(
            new Set([...allClusters].filter((c) => clusters.indexOf(c) > -1))
          )
        ).get(region)?.length || 0}
        )
      </span>
    )}
  ></OrFilter>
);

export default RegionFilter;
