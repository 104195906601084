import { FC } from "react";
import Cluster from "../../api/types/clusters/Cluster";
import ClustersBy from "../../utils/ClustersBy";
import OrFilter from "./OrFilter";

const clusterAccounts = (clusters: Cluster[]) => {
  return Array.from(new Set(clusters.map((c) => c.details.account)));
};

type AccountFilterProps = {
  clusters: Cluster[];
  allClusters: Cluster[];
  toggleFilter: {
    value: boolean;
    handler: (action: React.SetStateAction<boolean>) => void;
  };
  state: {
    value: string[];
    handler: (action: React.SetStateAction<string[]>) => void;
  };
};

const AccountFilter: FC<AccountFilterProps> = ({
  clusters,
  allClusters,
  toggleFilter,
  state,
}) => (
  <OrFilter
    filterLabel="Account"
    filterActive={toggleFilter.value}
    setFilterActive={toggleFilter.handler}
    filter={state.value}
    setFilter={state.handler}
    items={allClusters}
    itemsBy={clusterAccounts}
    itemsGroupedBy={ClustersBy.byAccount}
    itemLabel={(account) => (
      <span>
        {account} ({ClustersBy.byAccount(clusters).get(account)?.length || 0})
      </span>
    )}
  ></OrFilter>
);

export default AccountFilter;
