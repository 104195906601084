import { Col, Form } from "react-bootstrap";

type SetState<T> = (state: React.SetStateAction<T>) => void;

interface AndFilterProps<T, U> {
  filterLabel: string;
  setState: SetState<U | undefined>;
  itemsBy: (items: T[]) => U[];
  items: T[];
  itemLabel: (item: U) => React.ReactNode;
  filter: U | undefined;
  defaultFilter?: U;
}

function AndFilter<T, U>(props: React.PropsWithChildren<AndFilterProps<T, U>>) {
  return (
    <Form.Group as={Col}>
      <Form.Label column sm={2}>
        <b>
          <Form.Check
            label={props.filterLabel}
            onChange={(e) => {
              if (e.target.checked) {
                props.setState(props.defaultFilter);
              } else {
                props.setState(undefined);
              }
            }}
            checked={props.filter !== undefined}
          />
        </b>
      </Form.Label>
      {props.itemsBy(props.items).map((item: U) => {
        return (
          <Form.Check
            type="radio"
            key={`${props.filterLabel.toLowerCase()}-${item}-radio`}
            id={`${props.filterLabel.toLowerCase()}-${item}-radio`}
            name={`${props.filterLabel.toLowerCase()}-radio`}
            label={props.itemLabel(item)}
            onChange={() => props.setState(item)}
            checked={item === props.filter}
          />
        );
      })}
    </Form.Group>
  );
}

export default AndFilter;
