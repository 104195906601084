import { faFilter, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import getClusters from "../api/GetClusters";
import Cluster from "../api/types/clusters/Cluster";
import ClustersBy from "../utils/ClustersBy";
import ClusterList from "./ClusterList";
import AccountFilter from "./filters/AccountFilter";
import EnvironmentFilter from "./filters/EnvironmentFilter";
import RegionFilter from "./filters/RegionFilter";
import VersionFilter from "./filters/VersionFilter";
import GitHashVersion from "./GitHashVersion";

type ClusterAppProps = {
  loadingState: {
    value: boolean;
    handler: (action: React.SetStateAction<boolean>) => void;
  };
  connectedToHeimdallState: {
    value: boolean;
    handler: (action: React.SetStateAction<boolean>) => void;
  };
};

const ClusterApp: FC<ClusterAppProps> = ({
  loadingState,
  connectedToHeimdallState,
}) => {
  const [versionFilter, setVersionFilter] = useState<string | undefined>(
    undefined
  );
  const [environmentFilter, setEnvironmentFilter] = useState<
    string | undefined
  >(undefined);

  const [regionFilterActive, setRegionFilterActive] = useState(false);
  const [regionFilter, setRegionFilter] = useState<Array<string>>([]);

  const [accountFilterActive, setAccountFilterActive] = useState(false);
  const [accountFilter, setAccountFilter] = useState<Array<string>>([]);

  const [clusters, setClusters] = useState(Array<Cluster>);
  const [allClusters, setAllClusters] = useState(Array<Cluster>);
  useEffect(() => {
    getClusters().then((r) => {
      loadingState.handler(false);
      if (r.success) {
        setClusters(r.clusters);
        setAllClusters(r.clusters);
      } else {
        connectedToHeimdallState.handler(r.error?.code !== "ECONNABORTED");
      }
    });
  }, [connectedToHeimdallState, loadingState]);

  useEffect(() => {
    let filteredClusters = [...allClusters];
    if (versionFilter !== undefined) {
      filteredClusters =
        ClustersBy.byVersion(filteredClusters)?.get(versionFilter) || [];
    }

    if (environmentFilter !== undefined) {
      filteredClusters =
        ClustersBy.byEnvironment(filteredClusters)?.get(environmentFilter) ||
        [];
    }

    if (regionFilterActive && regionFilter.length > 0) {
      filteredClusters = regionFilter.flatMap(
        (r) => ClustersBy.byRegion(filteredClusters)?.get(r) || []
      );
    }

    if (accountFilterActive && accountFilter.length > 0) {
      filteredClusters = accountFilter.flatMap(
        (a) => ClustersBy.byAccount(filteredClusters)?.get(a) || []
      );
    }

    setClusters(filteredClusters);
  }, [
    allClusters,
    versionFilter,
    environmentFilter,
    regionFilterActive,
    regionFilter,
    accountFilterActive,
    accountFilter,
  ]);
  if (!loadingState.value && connectedToHeimdallState.value) {
    return (
      <>
        <GitHashVersion clusters={clusters} />
        <Row>
          <VersionFilter
            clusters={clusters}
            allClusters={allClusters}
            value={versionFilter}
            changeState={setVersionFilter}
          />
          <RegionFilter
            clusters={clusters}
            allClusters={allClusters}
            toggleFilter={{
              value: regionFilterActive,
              handler: setRegionFilterActive,
            }}
            state={{ value: regionFilter, handler: setRegionFilter }}
          />
          <EnvironmentFilter
            clusters={clusters}
            allClusters={allClusters}
            state={{
              value: environmentFilter,
              handler: setEnvironmentFilter,
            }}
          />
          <AccountFilter
            clusters={clusters}
            allClusters={allClusters}
            toggleFilter={{
              value: accountFilterActive,
              handler: setAccountFilterActive,
            }}
            state={{ value: accountFilter, handler: setAccountFilter }}
          />
          <Col xs={2}>
            <Button
              className={"mb-2 col-12"}
              onClick={() => {
                setVersionFilter(undefined);
                setRegionFilterActive(false);
                setRegionFilter([]);
                setEnvironmentFilter(undefined);
                setAccountFilterActive(false);
                setAccountFilter([]);
              }}
              disabled={
                versionFilter === undefined &&
                !regionFilterActive &&
                environmentFilter === undefined &&
                !accountFilterActive
              }
            >
              <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon> Clear Filters
            </Button>
            <Button
              className={"col-12"}
              href="https://token.schip.io/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faKey}></FontAwesomeIcon> Generate token
            </Button>
          </Col>
        </Row>
        <ClusterList clusters={clusters} />
      </>
    );
  }
  return <></>;
};

export default ClusterApp;
