import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, InputGroup } from "react-bootstrap";
import React, { FC, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

interface CopiableURLProps {
  url: string;
}

const CopiableURL: FC<CopiableURLProps> = ({ url }) => {
  const [copiedValue, setCopiedValue] = useState({
    value: "",
    copied: false,
  });

  return (
    <InputGroup>
      <input
        type="text"
        className="form-control input-monospace input-sm color-bg-subtle"
        value={url}
        readOnly={true}
      />
      {copiedValue.copied && (
        <Button variant="success">
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      )}
      {!copiedValue.copied && (
        <CopyToClipboard
          text={url}
          onCopy={(text, result) => {
            if (result) {
              setCopiedValue({ value: text, copied: true });
              setTimeout(function () {
                setCopiedValue({ ...copiedValue, copied: false });
              }, 500);
            }
          }}
        >
          <Button variant="outline-secondary">
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        </CopyToClipboard>
      )}
    </InputGroup>
  );
};

export default CopiableURL;
