import { Form, Col } from "react-bootstrap";

type SetState<T> = (state: React.SetStateAction<T>) => void;

interface OrFilterProps<T, U> {
  filterLabel: string;
  setFilter: SetState<U[]>;
  setFilterActive: (state: React.SetStateAction<boolean>) => void;
  filterActive: boolean | undefined;
  itemsBy: (items: T[]) => U[];
  itemsGroupedBy: (items: T[]) => Map<U, T[]>;
  items: T[];
  itemLabel: (item: U) => React.ReactNode;
  filter: U[];
  defaultFilter?: U;
}

function OrFilter<T, U>(props: React.PropsWithChildren<OrFilterProps<T, U>>) {
  return (
    <Form.Group as={Col}>
      <Form.Label
        column
        sm={2}
        htmlFor={`${props.filterLabel}-filter-active-check`}
      >
        <b>
          <Form.Check
            id={`${props.filterLabel}-filter-active-check`}
            label={props.filterLabel}
            onChange={(e) => {
              if (!e.target.checked) {
                props.setFilter([]);
              } else {
                props.setFilter(
                  Array.from(props.itemsGroupedBy(props.items).keys())
                );
              }
              props.setFilterActive(!props.filterActive);
            }}
            checked={props.filterActive}
          />
        </b>
      </Form.Label>
      {props.itemsBy(props.items).map((item: U) => {
        return (
          <Form.Check
            key={`filter-${props.filterLabel}-${item}-check`}
            id={`filter-${props.filterLabel}-${item}-check`}
            name={`${props.filterLabel}-regions-check`}
            onChange={(e) => {
              if (e.target.checked) {
                props.setFilterActive(true);
                props.setFilter([...props.filter, item]);
              } else {
                if (props.filter.length === 1) {
                  props.setFilterActive(false);
                }
                props.setFilter([...props.filter].filter((it) => it !== item));
              }
            }}
            checked={props.filter.filter((f) => f === item).length > 0 || false}
            label={props.itemLabel(item)}
          />
        );
      })}
    </Form.Group>
  );
}

export default OrFilter;
