import { FC } from "react";
import Cluster from "../../api/types/clusters/Cluster";
import ClustersBy from "../../utils/ClustersBy";
import AndFilter from "./AndFilter";
import EnvironmentBadge from "../EnvironmentBadge";

const clusterEnvironments = (clusters: Cluster[]) => {
  return Array.from(new Set(clusters.map((c) => c.details.environment))).sort();
};

type EnvironmentFilterProps = {
  clusters: Cluster[];
  allClusters: Cluster[];
  state: {
    value: string | undefined;
    handler: (action: React.SetStateAction<string | undefined>) => void;
  };
};

const EnvironmentFilter: FC<EnvironmentFilterProps> = ({
  clusters,
  allClusters,
  state,
}) => (
  <AndFilter
    filterLabel="Environment"
    filter={state.value}
    defaultFilter={"pro"}
    setState={state.handler}
    itemsBy={clusterEnvironments}
    items={allClusters}
    itemLabel={(item) => (
      <span>
        <EnvironmentBadge environment={item} /> (
        {ClustersBy.byEnvironment(clusters).get(item)?.length || 0}/
        {ClustersBy.byEnvironment(allClusters).get(item)?.length || 0})
      </span>
    )}
  ></AndFilter>
);

export default EnvironmentFilter;
