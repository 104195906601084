import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import "./App.css";
import Header from "./components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ClusterApp from "./components/ClusterApp";

function App() {
  const [loadingClusters, setLoadingClusters] = useState(true);
  const [connectedToHeimdall, setConnectedToHeimdall] = useState(true);

  return (
    <main>
      <div className="container">
        <Header />
        {loadingClusters && (
          <Alert>
            <FontAwesomeIcon icon={faSpinner} className="me-2" />
            Loading data...
          </Alert>
        )}
        {!loadingClusters && !connectedToHeimdall && (
          <Alert variant="danger">
            <FontAwesomeIcon icon={faBan} className="me-2" /> Could not get the
            clusters. Are you connected to Heimdall?
          </Alert>
        )}
        <ClusterApp
          loadingState={{
            value: loadingClusters,
            handler: setLoadingClusters,
          }}
          connectedToHeimdallState={{
            value: connectedToHeimdall,
            handler: setConnectedToHeimdall,
          }}
        />
      </div>
    </main>
  );
}

export default App;
