import { FC } from "react";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Flag from "react-world-flags";
import Cluster from "../api/types/clusters/Cluster";
import AWS_REGIONS from "../utils/AWSRegions";
import CopiableURL from "./CopiableURL";
import EnvironmentBadge from "./EnvironmentBadge";
import GitHubVersionLink from "./GitHubVersionLink";

type ClusterListProps = {
  clusters: Cluster[];
};

const ClusterList: FC<ClusterListProps> = ({ clusters }) => (
  <Row className="row-cols-2">
    {clusters.map((cluster) => {
      return (
        <Col>
          <Card className="mb-3">
            <Card.Header>
              <Row>
                <Col md={1} xs={2}>
                  <Flag
                    code={AWS_REGIONS.get(cluster.details.region)}
                    height={16}
                    fallback={<span>Unknown</span>}
                  />
                </Col>
                <Col>
                  <p>
                    <EnvironmentBadge
                      environment={cluster.details.environment}
                    />
                    {cluster.name}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{cluster.provisioner.name}</Tooltip>}
                    >
                      <span>
                        <img
                          src={
                            cluster.provisioner.name === "eks"
                              ? "/eks.png"
                              : "/github.jpg"
                          }
                          width={24}
                          className="ms-1 me-1"
                          alt={cluster.provisioner.name}
                        />
                      </span>
                    </OverlayTrigger>
                    {" @ "} <GitHubVersionLink version={cluster.version} />
                    {" running in "}
                    {cluster.details.account}
                  </p>
                  <p className="text-500 fs--2 mb-0"></p>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                {cluster.links.map((link) => {
                  return (
                    <Col>
                      <a href={link.url} target="_blank">
                        {link.name}
                      </a>
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col>
                  <span>
                    <b>Public API</b>
                  </span>
                  <CopiableURL url={cluster.details.publicAPI}></CopiableURL>
                </Col>
                <Col>
                  <span>
                    <b>Admin API</b>
                  </span>
                  <CopiableURL url={cluster.details.adminAPI}></CopiableURL>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      );
    })}
  </Row>
);

export default ClusterList;
