import { faCheck, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import Cluster from "../api/types/clusters/Cluster";
import GitHubVersionLink from "./GitHubVersionLink";

type GitHashVersionProps = {
  clusters: Cluster[];
};

type SingleShaVersionProps = {
  sha: string;
};

type MultipleShaVersionsProps = {
  sha: string[];
};

const clustersSameGitHash = (clusters: Cluster[]) => {
  return new Set(clusters.map((c) => c.version)).size == 1;
};

const SameVersionAlert: FC<SingleShaVersionProps> = ({ sha }) => (
  <Alert variant="success">
    <FontAwesomeIcon icon={faCheck} className="me-2" /> All the clusters are in
    the same git version <GitHubVersionLink version={sha} />
  </Alert>
);

const DifferentVersionsAlert: FC<MultipleShaVersionsProps> = ({ sha }) => (
  <Alert variant="warning">
    <FontAwesomeIcon icon={faWarning} className="me-2" />
    Not all the clusters are in the same git version. There are{" "}
    {new Set(sha).size} versions.
  </Alert>
);

const GitHashVersion: FC<GitHashVersionProps> = ({ clusters }) => {
  return (
    <Row>
      <Col>
        {clustersSameGitHash(clusters) ? (
          <SameVersionAlert sha={clusters[0]?.version} />
        ) : (
          <DifferentVersionsAlert sha={clusters.map((c) => c.version)} />
        )}
      </Col>
    </Row>
  );
};

export default GitHashVersion;
