import { FC } from "react";
import Cluster from "../../api/types/clusters/Cluster";
import Provisioner from "../../api/types/clusters/Provisioner";
import groupBy from "../../utils/GroupBy";
import AndFilter from "./AndFilter";

const clustersBy = (v: keyof Provisioner) => (clusters: Cluster[]) =>
  groupBy(clusters, (c) => c.provisioner[v]);

const clusterVersions = (clusters: Cluster[]) => {
  return Array.from(
    new Set(clusters.map((c) => c.provisioner.template))
  ).sort();
};

type VersionFilterProps = {
  clusters: Cluster[];
  allClusters: Cluster[];
  value: string | undefined;
  changeState: (action: React.SetStateAction<string | undefined>) => void;
};

const VersionFilter: FC<VersionFilterProps> = ({
  clusters,
  allClusters,
  value,
  changeState,
}) => {
  return (
    <AndFilter
      filterLabel="Version"
      filter={value}
      setState={changeState}
      itemsBy={clusterVersions}
      items={allClusters}
      itemLabel={(version) => (
        <span>
          {version} ({clustersBy("version")(clusters).get(version)?.length || 0}
          )
        </span>
      )}
    />
  );
};

export default VersionFilter;
