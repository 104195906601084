import { FC } from "react";

type GithubVersionLinkProps = {
  version: string;
};

const GitHubVersionLink: FC<GithubVersionLinkProps> = ({ version }) => (
  <a
    href={`https://github.mpi-internal.com/schip/schip-environments/commit/${version}`}
    target="_blank"
    title={version}
  >
    {version.substring(0, 8)}...
  </a>
);

export default GitHubVersionLink;
