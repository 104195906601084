import axios from "axios";
import GetClustersResponse from "./types/GetClusterResponse";

interface ErrorHandling {
  success: boolean;
  error?: { message: string; code?: string };
}

type GetClusterResponseOrError = ErrorHandling & GetClustersResponse;

type QueryParams = {
  version: string | undefined;
  environment: string | undefined;
};

async function getClusters(
  q?: QueryParams
): Promise<GetClusterResponseOrError> {
  const baseURL = "https://clusters.schip.io";
  let reqURL = new URL(baseURL);

  const params = new URLSearchParams();
  if (q?.version) {
    params.append("versions", q.version);
  }
  if (q?.environment) {
    params.append("environment", q.environment);
  }

  reqURL.search = params.toString();

  try {
    const { data, status } = await axios.get<GetClustersResponse>(
      reqURL.toString(),
      {
        timeout: 1000 * 2,
        headers: {
          Accept: "application/json",
        },
      }
    );

    // 👇️ "response status is: 200"
    console.log("response status is: ", status);

    return { ...data, success: true };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        clusters: [],
        success: false,
        error: { message: error.message, code: error.code },
      };
    } else {
      return {
        clusters: [],
        success: false,
        error: { message: "An unexpected error occurred" },
      };
    }
  }
}

export default getClusters;
