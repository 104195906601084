import Cluster from "../api/types/clusters/Cluster";
import Details from "../api/types/clusters/Details";
import Provisioner from "../api/types/clusters/Provisioner";
import groupBy from "./GroupBy";

const byProvisioner = (v: keyof Provisioner) => (clusters: Cluster[]) =>
  groupBy(clusters, (c) => c.provisioner[v]);

const byDetails = (v: keyof Details) => (clusters: Cluster[]) =>
  groupBy(clusters, (c) => c.details[v]);

const byVersion = (clusters: Cluster[]) => byProvisioner("template")(clusters);

const byEnvironment = (clusters: Cluster[]) =>
  byDetails("environment")(clusters);

const byRegion = (clusters: Cluster[]) => byDetails("region")(clusters);

const byAccount = (clusters: Cluster[]) => byDetails("account")(clusters);

const ClustersBy = {
  byVersion,
  byEnvironment,
  byRegion,
  byAccount,
};

export default ClustersBy;
